@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
/* night mode */
:root {
  --background: #1c3553;
  --backgroundHeader: #112a45;
  --backgroundInput: rgba(255, 255, 255, 0);
  --text: #c5cdd3;
  --borderColor: #8e8e8e61;
}
/* day mode */
:root {
  --background: #F0F2F5;
  --backgroundHeader: white;
  --backgroundInput:white;
  --text: black;
  --borderColor: #dadada;
}

#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    /* background: #fff; */
    background: var(--backgroundHeader);
  }
  .logo {
    /* float: left; */
    /* width: 120px; */
    height: 31px;
    margin: auto 20px auto 20px;
    background: rgba(255, 255, 255, 0.3);
  }
  .main-frame{
    padding: 24px;
    min-height: 70vh;
  }
  .window-frame{
    min-height: 100vh!important;
  }
  .main-frame-content{
    margin: 0px 16px!important;
  }
  .login-form{
    margin-top: 35vh!important;
    padding: 50px;
    margin: auto;
    max-height: 249px;
    background: rgb(255 255 255 / 81%);
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }

.ant-layout-header {
  padding: 0 15px!important;
}
.ant-form-item-label > label {
  font-size: 15px!important;
}
.ant-input-number {
  width: 100%!important;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
/*
.ant-tree .ant-tree-treenode {
  display: block;
  align-items: flex-start;
  width: 100%;
}
*/

.ant-tree-title{
  display: inline-block;
  background-color: aliceblue;
  padding: 3px 30px 3px 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 6px;
  box-shadow: 1px 1px #e5e5e5;
  outline: none;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  /* background-color: #eaecefeb; */
  border-radius: 10px;
} 
.table_import{
  width: 100%;
  
  border: 1px solid lightgray;
}
.table_heading{
  background-color: lightgray;
  text-align: center;
}
.table_import >tr > th{
  padding: 10px;
}
.table_import >tr > td{
  text-align: center;
  padding: 7px;
  border-bottom: 1px solid lightgray;
}
.ql-editor {
  min-height: 100px;
}
.Stock_Warehouse.active{
  background-color: lightgreen;
  color: white!important;
}
.Stock_Variation.active{
  background-color: lightgreen;
  color: white!important;
}

/* dark mode */

.main-frame{
  /* background-color: transparent; */
  -webkit-backdrop-filter: blur(5px)!important;
          backdrop-filter: blur(5px)!important;
}
main{
  color: var(--text);
  background: var(--background);
}
.site-layout{
  background: var(--background);
}
h1, h2, h3, h4, h5, h6 {
  color: var(--text);
}
.ant-table {
  background: var(--backgroundHeader);
}
.ant-table-thead > tr > th {
  color:var(--text);
  /* background: var(--backgroundHeader); */
  border-bottom: 1px solid var(--borderColor);
  transition: background 0.3s ease;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--borderColor);
  /* color: var(--text); */
  transition: background 0.3s;
}
.ant-drawer-content {
  background-color: var(--backgroundHeader);
  color: var(--text)
}
.ant-drawer-header {
  color: var(--text);
  background: var(--background);
}
.ant-drawer-title {
  color:var(--text);
}
.ant-form-item-label > label {
  color: var(--text);
}
.ant-drawer-body p.site-description-item-profile-p {
  color:var(--text);
}
.ant-col{
  color: var(--text);
}
.ant-breadcrumb > span:last-child {
  color:var(--text);
}
.ant-breadcrumb {
  color: var(--text);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--backgroundInput);
  border: 1px solid #d9d9d9;
}
.ant-input-number {
  background-color: var(--backgroundInput);
  color: var(--text)
}
.ant-select-selection-item{
  background-color: var(--backgroundInput);
  color: var(--text)
}
.ant-input-number-input-wrap{
  background-color: var(--backgroundInput);
  color: var(--text)
}
.ant-picker{
  background-color: var(--backgroundInput);
  color: var(--text)
}
.ant-picker-input{
  background-color: var(--backgroundInput);
  color: var(--text)
}



/* orginal CSS */
.Item-list-header{
  border: 1px solid lightgray;
  padding: 5px 10px;
  margin-bottom: 1px;
}
.Item-list{
  border: 1px solid lightgray;
  padding: 10px 10px;
  margin-bottom: 1px;
  background: whitesmoke;
  margin: auto!important;
}
.history_table{
  width: 100%;
}
.history_table td{
  border:  1px solid lightgray;
  padding: 5px 5px;
  /* height: 50px; */
}
.displayblock{
  display: block!important ;
}


/* invoice css starts */

.account_table{
  width: 100%;
}
.account_table td{
  border:  1px solid lightgray;
  padding: 5px 5px;
  /* height: 50px; */
}
.product_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.product_table td{
  border:  1px solid lightgray;
  /* padding: 5px 2px; */
  /* height: 50px; */
  height: 20px;
}

.attendacne_table{
  width: 100%;
  text-align: center;
  
  /* border: 1px solid black; */
}

.attendacne_table th{
  border-bottom:  1px solid rgb(231, 231, 231);
  padding: 12px 10px;
  
}
.attendacne_table td{
  border-bottom:  1px solid rgb(235, 235, 235);
  margin-bottom: 2px;
  padding: 15px 10px;

}

.vat_table{
  width: 100%;
  text-align: center;
  border: 1px solid black;
}

.vat_table td{
  border:  1px solid lightgray;
  height: 30px;
}

.product_table_quick{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.product_table_quick td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.invoice_print_fontSize_quick{
  font-size: 14px;
}

.invoice_print_fontSize{
  font-size: 10px;
  /* font-size: 24px; */
}
.wordrobe_print_fontSize{
  font-size: 12px;
  /* font-size: 24px; */
}

.order_span{
  display: block;
  border: 1px solid lightgray;
  width: 80%;
  padding: 3px 1px;
  margin: auto;
}
.order_span_bill{
  display: block;
  border: 1px solid black;
  width: 100%;
  padding: 3px 5px;
  margin: auto;
  margin-bottom: 1px;
}
.order_span_service{
  display: block;
  border: 1px solid black;
  width: 80%;
  padding: 5px 5px;
}


.sponsorship_order_span{
  display:block;
  border: 1px solid lightgray;
  width: 100%;
  padding: 3px 5px;
  margin-top: -5px;
  margin-bottom: 5px;
}

/* invoice css ends */

.attendance_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.attendance_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.salary_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.salary_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}

.loan_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.loan_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}

.leave_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}

.leave_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.payslip_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}
.payslip_table th{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
  width: 50%;
}
.payslip_table td{
  border:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
  width: 25%;
}
.payslip_table input.ant-input-number-input {
  text-align: center;
}
.voucher_table{
  width: 100%;
  text-align: center;
  border: 2px solid black;
}
.voucher_table td{
  border-top:  1px solid lightgray;
  border-bottom:  1px solid lightgray;
  padding: 5px 2px;
  height: 50px;
}
.voucher_table_title{
  line-height: 40px;
}
.voucher_table_title td{
  margin-bottom: 10px;
}


.td_dotted{
  width: 20%;
  border-bottom: 1px dotted gray;
}
.td_dotted1{
  width: 30%;
  border-bottom: 1px dotted gray;
}

.summary_card{
  border-radius: 5px;
  color: black;
  font-weight: 600;
  font-size: 16px;
}


.table_purple{
  background-color: #7c238c;
  color: white;
}
.table_dark_Purple{
  background-color: #42033d;
  color: white;
}
.table_orange{
  background-color: orange;
  color: white;
}
.table_light_green{
  background-color: #80ff72;
  color: black;
}
.table_green{
  background-color: #F95738;
  color: #000000;
}
.table_yellow{
  background-color: #fff07c;
  color: black;
}
.table_light_yellow{
  background-color: #F0FFC2;
  color: black;
}
.table_gray{
  background-color: #8491a3;
  color: black;
}
.table_sky{
  background-color: #7ee8fa;
  color: black;
}
.table_red{
  background-color: rgb(252, 139, 139);
  color: black;
}
.table_light_red{
  background-color: #FFE4C0;
  color: black;
}
.rtable a{
  color: black;
}
.table_purple a{
  color: #F5F5F5;
}
.table_dark_Purple a{
  color: #F5F5F5;
}
.table_Cancelled{
  background-color: #100007;
  color: white;
}
.table_Cancelled a{
  color: white;
}
.rtable:hover a{
  color: black;
}
.rtable:hover{
  color: black;
}

.voucher_inputnumber > .ant-input-number-input-wrap{
  min-width: 400px;
}
.d-div > p {
  margin-bottom: 0px;
}
  

/* for id card */
:root{
    --white: #ffffff;
}
*{
    margin: 0;
    padding: 0;
}
.main_div {
    display: grid;
    grid-template-columns: 380px 380px;
    grid-template-rows: 640px;
    font-family: 'Open Sans', sans-serif;
    grid-gap: 10px;
}
.font_page {
	background: var(--white);
	text-align: center;
	box-shadow: 0 0 10px #bbbbbb;
	border-radius: 15px;
    
}
.back_page {
    background-color: #925A22;
    color: var(--white);
    text-align: center;
    box-shadow: 0 0 10px #bbbbbb;
    border-radius: 10px;
}
.bg_color {
    background: var(--white);
    position: relative;
    z-index: 1;
    padding: 15px 0 0 0;
    background-color: #925A22;
    border-radius: 10px;
  }
  .bg_color::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: var(--white);
    /* clip-path: polygon(100% 0, 100% 35%, 100% 100%, 83% 86%, 38% 81%, 20% 87%, 1% 100%, 0% 35%, 0 0); */
    content: "";
    z-index: -1;
    border-top-left-radius: 100%;
}
.bg_color::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: var(--white);
    /* clip-path: polygon(100% 0, 100% 35%, 100% 100%, 83% 86%, 38% 81%, 20% 87%, 1% 100%, 0% 35%, 0 0); */
    content: "";
    z-index: -1;
    border-top-right-radius: 100%;
}
.bg_color img {
	width: 180px;
	border-radius: 50%;
	height: 180px;
	object-fit: cover;
	display: block;
	margin: 0 auto;
	border: 2px solid;
	padding: 8px;
}


.bg_color h3 {
    color: var(--white);
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 20px;
    font-weight: 500;
  }
  .profile_info h3 {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .job_title {
	font-size: 24px;
	font-weight: 300;
	line-height: 30px;
	margin-bottom: 20px;
}
  .phone_number {
    font-size: 18px;
    line-height: 36px;
  }
  .phone_number i {
    font-size: 22px;
    width: 35px;
    height: 35px;
    border: 1px solid #000000;
    line-height: 35px;
  }
  .date_info {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-content: center;
    align-items: flex-end;
  }
  .id_card_logo img {
	max-width: 280px;
	display: block;
	margin: 0 auto;
    margin-bottom: 25px;
}
.font_page_discription{
    padding: 0 20px;
}
.profile_info {
	padding: 40px 0;
}
/* Back Post */
.shap {
	height: 300px;
	background: var(--white);
	border-radius: 10px 10px 38% 38%;
	justify-content: center;
	align-items: center;
	display: flex;
}


.address_info {
    display: flex;
    padding: 40px 0;
    position: relative;
  }

  .address_info::before {
	position: absolute;
	left: 50%;
	top: 0;
	width: 2px;
	height: 90%;
	background: #ffffff;
	content: "";
}
  .address_info .address:first-child {
    text-align: right;
    width: 50%;
    padding-right: 15px;
  }
  .address_info .address:last-child {
    text-align: left;
    width: 50%;
    padding-left: 15px;
  }
  .address span {
    display: block;
    font-size: 29px;
    font-weight: 500;
  }
  .footer_text p i {
    font-size: 22px;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    border: 1px solid #fff;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
  }
  .simple_text {
    color: #925A22;
    font-size: 53px;
    line-height: 39px;
    letter-spacing: 2px;
  }
  .simple_text small {
    text-align: right;
    display: block;
    opacity: 0.5;
  }
  .footer_text {
    text-align: left;
    padding-left: 12%;
  }
  .footer_text p {
    margin-bottom: 10px;
  }
  .notifi_bar{
    margin: "20px 0px";
    border-bottom: 1px solid #F0F2F5;
  }

  .glossy_card{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 1px 6px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 5px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  .glossy_card > .ant-col {
    padding-left: 1px!important;
    padding-right: 1px!important;
  }


.summary_card > .ant-card-body > h3{
  font-size: 14px!important;
  font-weight: 700;
  border-bottom: 2px solid rgb(202, 202, 202);
  display: inline-block;
  padding-bottom: 2px;
  font-family: sans-serif;
}

.stock{
  font-size: 14px!important;
  font-weight: 500;
 
}

.transactions >  .ant-card-body{
  padding: 10px;
}
.ant_right {
  text-align: right;
}

.ant-drawer-content img{
  max-height: 60px;
}
/* 373a59 */

/* AZRAN Start */
.azran-sidebar{
  background: #1f2a1d;
  color: white!important;
}
.azran-sidebar > .ant-menu.ant-menu-dark{
  background: #1f2a1d;
  color: white;
}

.azran-sidebar > .ant-layout-sider-trigger{
  background: #151c14;
  color: white;
}
.azran-sidebar .ant-menu-item a {
  color: white!important;
}
.azran-sidebar > .ant-menu-submenu-selected {
  /* background: #222437; */
  color: white;
}
.azran-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1b91ff;
}
.ant-menu-submenu-selected {
  color: red;
}
.azran-sidebar .ant-menu-sub.ant-menu-inline {
  background: #1f2a1d;
  color: white
}
.azran-sidebar > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: white;
}
.azran-sidebar > .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}

/* AZRAN End */



/* ELOR Start */
.elor-sidebar{
  background: #373a59;
  color: white!important;
}
.elor-sidebar > .ant-menu.ant-menu-dark{
  background: #373a59;
  color: white;
}

.elor-sidebar > .ant-layout-sider-trigger{
  background: #2e304a;
  color: white;
}
.elor-sidebar .ant-menu-item a {
  color: rgba(255, 255, 255, 0.717)!important;
}
.elor-sidebar > .ant-menu-submenu-selected {
  /* background: #222437; */
  color: white;
}
.elor-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1b91ff;
}
.ant-menu-submenu-selected {
  color: red;
}
.elor-sidebar .ant-menu-sub.ant-menu-inline {
  background: #2e304a;
  color: white
}
.elor-sidebar > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: white;
}
.elor-sidebar > .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #1890ff;
}

/* ELOR End */

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #ffffff00;
}
.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #13131368;
}

  /* end of id card */


/* label{
    color: white!important;
}
.ant-form-item-explain.ant-form-item-explain-error {
    color: #ffffff;
} */
